import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Container, Section, StyledLink } from '@components/common'
import { Button } from '@mrs-ui'

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(
        relativePath: {
          eq: "images/stock/it-software-consulting-toronto-58.jpeg"
        }
      ) {
        childImageSharp {
          fluid(maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Section
      verticalSpacing="xxl"
      backgroundImage={data.image.childImageSharp.fluid}
      theme="dark"
      overlay
    >
      <Container>
        <div className="md:w-5/12 w-full">
          <h1 className="mb-8">Partnering with some of the best</h1>
          <p className="text-large">
            From SMB to enterprise, public to private sector and across
            industries, organizations like yours trust MRS to help them conquer IT
            challenges, maximize investments in digital technology and gain a
            competitive edge.
          </p>
          <Button appearance="primary" link="/contact" className="mt-6">
            Find a fit for your business
          </Button>
        </div>
      </Container>
    </Section>
  )
}
export default Hero

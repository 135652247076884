import Head from '@components/Head'
import { ClientList, Hero, CTA } from '@components/pages/clients'
import React from 'react'
const ClientPage = () => {
  return (
    <>
      <Head
        title="Clients"
        description="MRS clients for data analytics, SharePoint, Office 365, cloud solutions, workflow automation, enterprise content management, intranet portals and more."
      />
      <Hero />
      <ClientList />
      <CTA />
    </>
  )
}
export default ClientPage
